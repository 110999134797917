.container-inputs{
    .roles{
        padding: 20px;
        border-radius: 20px;
        height: max-content;
        &::placeholder {
            color: #999; 
            font-style: italic; 
        }
    }
    .container-search-user{
        .input-and-button{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap:10px;
            button{
                padding: 20px;
                height: 60px;
                border-radius: 12px;
            }
        }
        input{
            border-radius: 20px;
            height: 60px;
        }
    }
    .container-content-users{
        padding: 16px 0px;
        border-top: 1px solid #E0E0E0;
    }
}
.container-item-user{
    padding: 12px;
    height: max-content !important;
    width: 100%;
    border: 1px solid #E0E0E0;
    border-radius: 20px !important;
    background-color: transparent !important;
    transition: 0.3s;
    .user-detail{
        display: flex;
        gap: 8px;
        align-items: center;
    }
}
.container-item-user:hover{
    transform: translateY(-3px);
    box-shadow: 0px 3px 10px rgba(0,0,0,.1);
}