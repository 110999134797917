@import "../../../styles/colors.scss";
.container-default{
    width: 100%;
    display: block;
    gap: 16px !important;
    .container-actions{
        width: 100%;
        display: flex;
        gap: 16px;
    }
}
.button-action{
    padding: 18px !important; 
    border-radius: 16px !important; 
    background:  $app-bg-color !important; 
    height: max-content !important;  
    flex: 1;   
    transition: all 0.3s ease;
    color: $app-bg-color;
    border: 1px solid transparent;
    p{
        color: $grey-3;
    }
    svg{       
        path{
            color: $grey-3 !important;
            stroke: $grey-3 !important;
        }    
    }
}
.button-action:hover{
    transform: translateY(-2px);
    border:  1px solid $sec-color;
    background: white !important;
    p{
        color: $sec-color;
    }
    svg{       
        path{
            color: $sec-color !important;
            stroke: $sec-color !important;
        }    
    }
}
