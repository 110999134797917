.text-name{
    font-size: 14px !important;
}

// Media query para pantallas con un máximo de 600px
@media (max-width: 600px) {
    .text-name {
        font-size: 10px !important;
    }
}
