$app-bg-color: #f8f8f8;
$sec-d: #0D2C8D;
$black: #000000;
$grey-0: #F8F8F8;
$grey-1: #E0E0E0;
$grey-3: #626262;
$sec_color: #123EC9;
$pri-color: #78C912;
$border-card: #5978D9;
$leagues-hex-gradient: #334EA7;
$leagues-gradient-1: linear-gradient(90.72deg, #0D2C8D 0.66%, #5978D9 99.44%);
$leagues-gradient-2: linear-gradient(91deg, #0A1F6F 0.66%, #4863B7 99.44%);