@import "./colors.scss";

.player-user-detail{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    p{
        text-align: left;
    }
}  
.container-item-player-user{
    display: block !important;
    padding: 12px;
    height: max-content !important;
    width: 100%;
    border: 1px solid #E0E0E0;
    border-radius: 16px !important;
    background-color: transparent !important;
    transition: 0.3s;
    .user-detail{
        display: flex;
        gap: 5px;
        align-items: center;
    }
}
.edit-button{    
    svg{
        path{
            stroke: $sec-color
        }
    }
}