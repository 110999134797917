body {
  font-family: "Source Sans 3 Variable", sans-serif;
}

.pac-container {
  z-index: 2000 !important;
  margin-top: 8px;
  padding: 8px;
  border-top: none;
  border-radius: 16px !important;
  overflow: hidden;
  box-shadow: 0px 4px 12px #e0e0e0;
}

.pac-container::after,
.pac-icon {
  content: none !important;
  display: none !important;
}

.pac-item {
  border-top: none;
  padding: 0px 16px;
  border-radius: 12px !important;
}

.custom-width .css-r6z5ec {
  width: 100%;
}

.content-width .css-r6z5ec {
  width: fit-content;
}
