.container-search-user{
    .input-and-button{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap:10px;
        button{
            padding: 20px;
            height: 60px;
            border-radius: 12px;
        }
    }
    input{
        border-radius: 20px;
        height: 60px;
    }
}